import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/Layout"
import { buildBlocks } from "@/services/blockGenerator"
import { ButtonLink } from "@/components/reusable/Button"

// @ts-ignore
const Page = ({ data, pageContext }) => {
  if (!data) {
    console.error("No data found for page")
    return null
  }
  const generatedBlocks = buildBlocks(data.wpPage.blocks)

  return (
    <Layout
      meta={data.wpPage.Meta}
      path={pageContext.pagePath}
      cta={data.wpPage.PageCTA}
      title={data.wpPage.title}
      themeOptions={data.wp.themeOptions}
    >
      {generatedBlocks}
      {data.wpPage.floatingButton.showButton && (
        <div
          className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
        >
          <ButtonLink
            className={``}
            link={data.wpPage.floatingButton?.buttonLink?.url}
            variant={`primary`}
          >
            {data.wpPage.floatingButton?.buttonLink?.title}
          </ButtonLink>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Page
